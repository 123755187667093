.MuiFormControl-root{
    width: 100%;
}
.passenger-booking{
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
}
/* .main{
    flex:1;
}
.footer{

} */